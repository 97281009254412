import styled from 'styled-components';
import type {
  ColumnAlignment,
  ColumnWidth,
  ParagraphRichTextColumn,
  ParagraphText as ParagraphTextSchema,
} from '@amplience/content-types/typings/c-paragraph-text';
import { RichTextPartial } from '@storefront/lib-global/partials/richText';
import { TypographyStyles, maxWidthPartial, media, spacing } from '@storefront/lib-global/stylings';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';

export type ParagraphTextProps = ParagraphTextSchema;

const ColumnAlignemnt = {
  top: 'flex-start',
  centre: 'center',
  bottom: 'flex-end',
};

const S = {
  Container: styled.div<{ $backgroundColor: string }>`
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    display: flex;
    justify-content: center;

    @media ${media.greaterThan('xx')} {
      ${maxWidthPartial({ maxWidth: 1300, withPadding: false })}
    }
  `,
  ParagraphTextContainer: styled.div<{
    $paragraphAlignment: string;
    $columnAlignment?: ColumnAlignment;
    $columnWidth?: ColumnWidth;
  }>`
    ${maxWidthPartial({ withPaddingXL: true })}
    align-items: ${({ $columnAlignment }) => ColumnAlignemnt[$columnAlignment as keyof typeof ColumnAlignemnt]};
    display: flex;
    flex-direction: column;
    padding-block: ${spacing.XS};

    @media ${media.greaterThan('lg')} {
      flex-direction: ${({ $paragraphAlignment }) => $paragraphAlignment === 'Two Column Paragraph' && 'row'};
      gap: ${({ $paragraphAlignment }) => $paragraphAlignment === 'Two Column Paragraph' && `${spacing.M}`};
      padding-block: ${spacing.XS};
      width: ${({ $columnWidth }) => `calc(${$columnWidth})`};
    }
  `,
  ParagraphText: styled(RichTextPartial)<{ $textColor: string; $paragraphAlignment: string }>`
    color: ${({ $textColor }) => $textColor};
    flex: ${({ $paragraphAlignment }) => ($paragraphAlignment === 'Two Column Paragraph' ? '0 1 50%' : 'unset')};
    width: 100%;

    * {
      margin-bottom: ${spacing.XXS};
    }

    img {
      margin: 0;
    }

    @media ${media.greaterThan('lg')} {
      padding-inline: ${({ $paragraphAlignment }) => $paragraphAlignment === 'Narrow' && '20%'};

      * {
        margin-top: 0;
      }
    }
  `,
};

export function ParagraphText({
  columnAlignment,
  columnWidth,
  backgroundColor,
  paragraphAlignment = 'Default',
  text,
  textColor,
}: ParagraphTextProps) {
  return (
    <S.Container $backgroundColor={backgroundColor as string}>
      <S.ParagraphTextContainer
        $paragraphAlignment={paragraphAlignment}
        $columnAlignment={paragraphAlignment === 'Two Column Paragraph' ? columnAlignment : 'top'}
        $columnWidth={columnWidth}
      >
        {(text as ParagraphRichTextColumn[] | undefined)?.map((textItem, idx) => (
          <S.ParagraphText
            key={`paragraph-text-column-${idx}`}
            text={getLocalizedValue(textItem.richText)}
            fontSize={TypographyStyles.Body.Medium.Regular}
            $textColor={textColor as string}
            $paragraphAlignment={paragraphAlignment as string}
          />
        ))}
      </S.ParagraphTextContainer>
    </S.Container>
  );
}

export default ParagraphText;
